//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaHeader from '@/components/SanghaHeader.vue';
import { ApiService, Auth } from '@/services/api.service';

export default {
  name: 'Login',
  data() {
    return {
      state: 'default',
      email: null,
      code: null,
      errors: [],
      step: 1, // 1 or 2
      inProgress: false,
      isCodeInvalid: false,
    };
  },
  components: {
    SanghaHeader,
  },
  methods: {
    submitSendEmail(e) {
      e.preventDefault();
      this.errors = [];

      ApiService.clearHeader();

      // STEP 1 - ask api for token for email address

      this.isCodeInvalid = false;
      this.inProgress = true;
      // console.log("Start recovery")
      Auth.startRecovery({
        email: this.email,
      }).then(
        (response) => {
          // console.log("response", response);
          this.inProgress = false;
          let result = response.data.data.startOnboarding;
          if (result && result.success && result.onboarding.token) {
            this.$store.dispatch('setApiToken', {
              email: this.email,
              token: result.onboarding.token,
            });
            this.step = 2;
          } else {
            this.$q.notify({
              type: 'negative',
              message: this.$i18n.t('login.error.submit_error'),
            });
          }
        },
        (e) => {
          console.log('error', e);

          this.inProgress = false;
          this.errors.push(this.$i18n.t('login.error.invalid_code'));
          this.$q.notify({
            type: 'negative',
            message: this.$i18n.t('login.error.submit_error'),
          });
        }
      );
    },
    submitLogin(e) {
      e.preventDefault();
      this.errors = [];

      if (this.errors.length) return;

      // verify email
      this.inProgress = true;
      ApiService.setHeader();
      Auth.finishRecovery({
        email: this.email,
        email_verification_code: this.code,
      }).then(
        (response) => {
          this.inProgress = false;
          let responseData = response.data.data.verifyOnboarding;
          if (
            responseData &&
            responseData.success &&
            responseData.onboarding &&
            responseData.onboarding.existingUser &&
            responseData.onboarding.existingUser.id
          ) {
            this.$store.commit(
              'login',
              responseData.onboarding.existingUser.id
            );

            // login action using JWT
            Auth.login({
              email: this.email,
              password: responseData.passcode,
            }).then((loginResponse) => {
              let loginResponseData = loginResponse.data.data.sanghaJwt;

              if (
                !loginResponseData.success ||
                loginResponseData.jwtSangha.mantra !=
                  'OM A HUM VAJRA GURU PADMA SIDDHI HUM'
              ) {
                this.errors.push(
                  this.$i18n.t('login.error.cannot_verify_email')
                );
                this.$store.commit('removeApiToken');
              } else {
                this.$store.commit('setJWTToken', {
                  token: loginResponseData.jwtSangha.token,
                  refresh: loginResponseData.jwtSangha.refreshToken,
                });

                /// decide where to go depending on params:
                if (this.$route.query && this.$route.query.returnTo) {
                  this.$router.replace(this.$route.query.returnTo);
                } else {
                  this.$router.replace({ name: 'events' });
                }
              }
            });
          } else {
            this.errors.push(this.$i18n.t('login.error.cannot_verify_email'));
            this.$store.commit('logout');
            this.$q.notify({
              type: 'negative',
              message: this.$i18n.t('login.error.submit_error'),
            });
          }
        },
        () => {
          this.inProgress = false;
          this.errors.push(this.$i18n.t('login.error.invalid_code'));
          // this.$q.notify({
          //   type: 'negative',
          //   message: "Sorry, something went wrong.",
          // })
          if (this.code.length > 0) this.isCodeInvalid = true;
        }
      );
    },
  },
  computed: {
    getState() {
      return this.$store.state.loggedIn;
    },
    getToken() {
      return this.$store.state.authorizedUser.token;
    },
  },
  mounted() {},
};
